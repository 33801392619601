import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import Ball from './ball';
import {useTranslation} from 'react-i18next';

export interface DrawResultProps {
  issueNum?: string;
  digit?: string;
  color?: string;
  onlyColor: boolean;
}

const DrawResult = ({
  issueNum = '',
  digit = '',
  color = 'g',
  onlyColor = false,
}: DrawResultProps) => {
  const {i18n} = useTranslation();
  return (
    <View style={[theme.flex.row]}>
      <View style={[theme.flex.flex1]}>
        <Text fontFamily="fontInterBold" size="medium">
          {issueNum}
        </Text>
        <Text fontFamily="fontInterBold" size="medium">
          {i18n.t('game-page.label.drawResult')}:
        </Text>
      </View>
      <div className="flex items-center justify-center w-[10.25rem] h-10 border border-[var(--game-line)] bg-bg rounded-lg">
        <Ball
          digit={digit === '' ? '-' : digit}
          color={color}
          onlyColor={onlyColor}
          disabled={digit === ''}
          ballSize={32}
          fontSize={14}
          // fontColor={'var(--T4)'}
        />
      </div>
    </View>
  );
};

export default DrawResult;
