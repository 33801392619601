import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import Ball from '../../components/ball';
import theme from '@/style';
const NUMBER = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export interface AnalyzeItemProps {
  index?: number;
  issueNum?: string;
  digit?: string;
  color?: string;
}

const AnalyzeItem = ({
  index = 0,
  digit = '0',
  issueNum,
  color,
}: AnalyzeItemProps) => {
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? '#30343D' : 'var(--image)',
    };
  }, [index]);

  const renderBall = (current: string) => {
    if (current === digit) {
      return (
        <Ball
          digit={digit}
          ballSize={20}
          color={color}
          fontSize={10}
          onlyColor={false}
        />
      );
    }
    return (
      <View style={[styles.ball, styles.border]}>
        <Text fontSize={10} fontFamily="fontInterBold">
          {current}
        </Text>
      </View>
    );
  };

  return (
    <View
      style={[
        backgroundColor,
        theme.padding.l,
        theme.flex.row,
        theme.margin.lrl,
        theme.flex.centerByCol,
      ]}>
      <View style={[theme.margin.rightm]}>
        <Text>{issueNum}</Text>
      </View>
      <View
        style={[
          theme.flex.flex1,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
        ]}>
        {NUMBER.map((item, _i) => (
          <View key={_i}>{renderBall(item)}</View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ball: {
    width: 20,
    height: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  border: {
    borderWidth: 0.6,
    borderColor: '#9FA5AC',
  },
});

export default AnalyzeItem;
