import OrderCard from '@/components/business/order/order-card';
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import DrawResult from '../../components/draw-result';
import ColorResult from '../../components/color-result';
import PageNumber from '@/components/business/games/page';
import {ColorListItem, getOrderListColor} from '../../color-service';
import dayjs from 'dayjs';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {copyText, getUUID, goTo, transfor} from '@/utils';

import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import {getShareAward} from '@/services/global.service';
import SharePanel from '@/components/business/share-panel/share-panel';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
import {SafeAny} from '@/types';
import {hasIM} from '@/config';

const pageSize = 3;

const MyOrder = ({
  isActive,
  refreshCode = 0,
}: {
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const {shareSuccess, shareFail} = useShareToast();
  const [list, setList] = React.useState<ColorListItem[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const {i18n} = useTranslation();
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const panelRef = React.useRef<DrawerRef>(null);

  const getOrderList = async (pageNo: number = 1) => {
    try {
      if (!firstLoad) {
        setLoading(true);
      }
      const yearMonth = dayjs().format('YYYYMM');
      const res = await getOrderListColor({
        orderStatus: '3',
        pageNo,
        pageSize,
        yearMonth,
      });
      if (res) {
        setTotal(res.totalSize);
        setList(res.content);
        const award = await getShareAward('color');
        setShareAward(Math.floor(award));
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isActive) {
      if (!globalStore.token) {
        goTo('Login');
        setFirstLoad(false);
        return;
      }
      getOrderList().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  React.useEffect(() => {
    if (refreshCode && isActive) {
      if (!globalStore.token) {
        return;
      }
      getOrderList().then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const onCopy = (id: string) => {
    copyText(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  return (
    <>
      <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
        {!firstLoad && !loading && list && list.length === 0 ? (
          <NoData />
        ) : null}
        {list.map((item, _i) => (
          <View key={_i}>
            <OrderCard
              id={item.groupOrderNo}
              gameName={item.gameName}
              payment={item.totalAmount}
              awardAmount={item.awardAmount}
              drawContainerStyle={[{backgroundColor: 'var(--card)'}]}
              drawTime={dayjs(item.openTime).format('DD-MM hh:mm A')}
              betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm:ss A')}
              onCopy={() => onCopy(item.groupOrderNo)}
              hideShare={!hasIM}
              result={
                <DrawResult
                  onlyColor={false}
                  issueNum={item.issueNo}
                  digit={item.digitResult}
                  color={item.colorResult}
                />
              }
              status={item.openStatus === 1 ? 2 : item.awardAmount > 0 ? 1 : 0}
              onShare={() => {
                const user = localStorage.getItem('user') || '{}';
                const userId = JSON.parse(user).userId;
                setThreeOrderData({
                  userId,
                  data: transfor({...item, userId}, 'color', {
                    from: 'order',
                  }),
                });
                panelRef.current?.open();
              }}
              hasAward={Boolean(shareAward)}
            />
            <ColorResult
              subsetList={item.subsetList}
              isOpen={item.openStatus !== 1}
            />
          </View>
        ))}
        {total > 3 ? (
          <PageNumber
            page={initPage}
            total={total}
            pageSize={pageSize}
            onPageChange={p => {
              setInitPage(p);
              getOrderList(p).then();
            }}
          />
        ) : null}
      </Spin>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType="color"
          hasReward={Boolean(shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              {
                gameCode: 'color',
                orderNo: getUUID(),
                threeOrderData,
              },
              (info: IShareInfo) => shareToChat(info),
              () => {
                shareSuccess(shareAward);
                setShareAward(0);
              },
              shareFail,
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default React.memo(MyOrder);
