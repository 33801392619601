import theme from '@/style';
import React from 'react';
import {View, StyleSheet, Image, ScrollView} from 'react-native';
import {BallProps} from './components/ball';
import BetsBall from './components/bets-ball';
import ColorBetsDot from '@/components/svgs/basic/color-bets-dot';
import ColorBetsBg from '@/components/svgs/basic/color-bets-bg';
import {useResponsiveDimensions} from '@/utils';

const ColorBets = ({betsBall = []}: {betsBall: BallProps[]}) => {
  const {width} = useResponsiveDimensions();
  return (
    <View style={[styles.container]}>
      <View
        style={[theme.flex.row, theme.flex.between, theme.flex.centerByCol]}>
        <ColorBetsDot
          color={theme.backgroundColor.colorBetsDotBg}
          width={styles.dot.width}
          height={styles.dot.height}
        />
        <svg width={64} height={20}>
          <text
            x="0"
            y="15"
            fill={theme.fontColor.colorMyBets}
            fontSize={14}
            fontFamily="DIN"
            fontWeight="700"
            stroke={'#FFFFFF45'}
            strokeWidth={2}
            paintOrder={'stroke'}>
            MY BETS
          </text>
        </svg>
        <ColorBetsDot
          color={theme.backgroundColor.colorBetsDotBg}
          width={styles.dot.width}
          height={styles.dot.height}
        />
      </View>
      <View style={[theme.margin.tops]}>
        <ColorBetsBg width={width - 32} height={65} />
        <Image
          style={[
            theme.position.abs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              bottom: 2,
              left: 8,
              height: 23,
              width: width - 32 - 16,
            },
          ]}
          source={require('@assets/imgs/games/color.07/bets-bg-bottom.webp')}
        />
        <View
          style={[
            theme.fill.fill,
            theme.position.abs,
            theme.padding.lrl,
            theme.padding.tops,
          ]}>
          <ScrollView
            style={[theme.fill.fill]}
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[
              {gap: theme.paddingSize.s, minWidth: width - 56},
            ]}>
            {betsBall.map((item, _i) => (
              <BetsBall key={_i} ball={item} />
            ))}
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.backgroundColor.colorBets,
    borderTopColor: theme.basicColor.white,
    borderTopWidth: 1,
    padding: 16,
    paddingTop: 12,
    marginBottom: 12,
  },
  dot: {
    height: 8,
    width: 107,
  },
});

export default ColorBets;
