import React from 'react';
import {View, StyleSheet, Image} from 'react-native';
import theme from '@/style';
import Ball from './components/ball';
import {ALL_BALLS} from './constant';
import {BetsInfo} from './color-service';
import {useResponsiveDimensions} from '@/utils';

export interface ColorBallsProps {
  disabled?: boolean;
  onBets?: (info: BetsInfo) => void;
}

const ColorBalls = ({disabled = false, onBets}: ColorBallsProps) => {
  const {width} = useResponsiveDimensions();
  return (
    <View style={[theme.padding.tbxxl, styles.container]}>
      <Image
        // eslint-disable-next-line react-native/no-inline-styles
        style={[styles.bottom, {width, height: 72 / (1124 / width), left: 0}]}
        source={require('@assets/imgs/games/color.07/ball-bottom-bg.webp')}
      />
      <View style={[theme.flex.row, theme.flex.between]}>
        {ALL_BALLS.slice(0, 5).map((item, index) => (
          <Ball
            onlyColor={false}
            onPress={() => {
              onBets &&
                onBets({
                  digit: item.digit,
                  color: item.color,
                  onlyColor: false,
                });
            }}
            key={index}
            digit={item.digit}
            disabled={disabled}
            color={item.color}
          />
        ))}
      </View>
      <View style={[theme.flex.row, theme.flex.between, theme.margin.topl]}>
        {ALL_BALLS.slice(5).map((item, index) => (
          <Ball
            onlyColor={false}
            onPress={() => {
              onBets &&
                onBets({
                  digit: item.digit,
                  color: item.color,
                  onlyColor: false,
                });
            }}
            key={index}
            digit={item.digit}
            disabled={disabled}
            color={item.color}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    position: 'relative',
    backgroundColor: theme.backgroundColor.colorBall,
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
  },
});

export default React.memo(ColorBalls);
