import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import FeeDetail from '@/components/business/order/order-fee-detail';
import MoreBtn from '@/components/business/order/more-btn';
import {SubsetListItem} from '../color-service';
import Ball from './ball';
import dayjs from 'dayjs';
import Price from '@/components/business/price';
import OrderListItemStatus from '@/components/business/order/order-list-item-status';
const HEADER = [
  {
    label: 'number',
    style: {
      width: 60,
    },
  },
  {
    label: 'time',
    style: {
      ...theme.flex.centerByCol,
      flex: 1,
    },
  },
  {
    label: 'payment',
    style: {
      ...theme.flex.centerByCol,
      flex: 1,
    },
  },
  {
    label: 'result',
    style: {
      flex: 1,
      ...theme.flex.alignEnd,
    },
  },
];

const whenNumToHide = 5;

const ColorResult = ({
  subsetList = [],
  isOpen = false,
}: {
  subsetList?: SubsetListItem[];
  isOpen?: boolean;
}) => {
  const {i18n} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);

  const list = React.useMemo(() => {
    if (subsetList.length <= whenNumToHide) {
      return subsetList;
    } else {
      return showMore ? subsetList : subsetList.slice(0, whenNumToHide);
    }
  }, [showMore, subsetList]);

  return (
    <View style={[theme.margin.lrl]}>
      <View style={[theme.flex.row, styles.header, theme.flex.between]}>
        {HEADER.map((item, _i) => (
          <View key={_i} style={item.style}>
            <Text>
              {i18n.t('bets-detail.label.' + item.label).toUpperCase()}
            </Text>
          </View>
        ))}
      </View>
      <View>
        {list.map((item, _i) => (
          <ColorResultItem
            key={_i}
            isOpen={isOpen}
            selectValue={item.selectValue}
            selectType={item.selectType}
            beetTime={dayjs(item.createTime).format('DD/MM/YYYY hh:mm:ss A')}
            payment={item.amount}
            deliveryAmount={item.deliveryAmount}
            fee={item.feeAmount}
            awardStatus={item.awardStatus}
            awardAmount={item.awardAmount}
          />
        ))}
      </View>
      {subsetList.length > whenNumToHide && !showMore && (
        <MoreBtn onPress={() => setShowMore(true)} />
      )}
    </View>
  );
};

export interface ColorResultItemProps {
  betTime?: string;
  payment?: number;
  index?: number;
  beetTime?: string;
  selectValue?: string;
  isOpen?: boolean;
  selectType?: number;
  awardStatus?: number;
  awardAmount?: number;
  deliveryAmount?: number;
  fee?: number;
}

const ColorResultItem = ({
  selectValue = '1',
  selectType,
  awardAmount = 0,
  payment,
  beetTime,
  fee,
  deliveryAmount,
  isOpen = false,
  index = 0,
}: ColorResultItemProps) => {
  const [showMore, setShowMore] = React.useState(false);
  // const {i18n} = useTranslation();

  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 1 ? 'var(--image)' : '#31343D',
    };
  }, [index]);

  const bg = React.useMemo(() => {
    if (selectType === 2) {
      return selectValue;
    } else {
      if (['2', '4', '6', '8'].includes(selectValue)) {
        return 'r';
      } else if (['1', '3', '7', '9'].includes(selectValue)) {
        return 'g';
      } else if (selectValue === '0') {
        return 'r,v';
      } else {
        return 'g,v';
      }
    }
  }, [selectValue, selectType]);

  return (
    <View style={[backgroundColor]}>
      <NativeTouchableOpacity
        onPress={() => setShowMore(!showMore)}
        style={[
          theme.flex.row,
          theme.flex.between,
          styles.itemAction,
          theme.padding.lrl,
          theme.flex.centerByCol,
        ]}>
        <View style={styles.number}>
          {
            <Ball
              color={bg}
              fontColor={selectType === 2 ? 'white' : theme.basicColor.dark}
              digit={selectValue}
              ballSize={28}
              fontSize={selectType === 2 ? 8 : 12}
              onlyColor={selectType === 2}
            />
          }
        </View>
        <View style={[styles.time]}>
          <Text textAlign="center">{beetTime}</Text>
        </View>
        <View style={styles.time}>
          <Price
            price={payment}
            textProps={{size: 'medium', textAlign: 'center'}}
          />
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.end,
            theme.flex.flex1,
          ]}>
          {/* <View style={[theme.flex.alignEnd]}>
            <Text>
              {i18n.t(
                !isOpen
                  ? 'bets-detail.label.noDraw'
                  : awardStatus === 1
                  ? 'bets-detail.label.won'
                  : 'bets-detail.label.noWin',
              )}
            </Text>
            {isOpen ? (
              <Price
                price={awardAmount}
                textProps={{size: 'medium', textAlign: 'right'}}
              />
            ) : (
              <Text size="medium" textAlign="right">
                ...
              </Text>
            )}
          </View>
          <View style={[arrowStyle, theme.margin.leftxxs]}>
            <Image style={styles.iconSize} source={downIcon} />
          </View> */}
          <OrderListItemStatus
            hasDetail
            showMore={showMore}
            status={!isOpen ? 2 : awardAmount > 0 ? 1 : 0}
            awardAmount={awardAmount}
          />
        </View>
      </NativeTouchableOpacity>
      {showMore && (
        <FeeDetail delivery={deliveryAmount} fee={fee} time={beetTime} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'var(--image)',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  itemAction: {
    height: 48,
  },
  iconSize: {
    width: 14,
    height: 14,
  },
  ballSize: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    width: 60,
  },
  time: {
    flex: 1,
    alignItems: 'center',
  },
});

export default React.memo(ColorResult);
