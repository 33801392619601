import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {ImageBackground} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {BALLS} from '../constant';

export interface BallProps {
  digit: string;
  disabled?: boolean;
  ballSize?: number;
  fontSize?: number;
  color?: string;
  onlyColor: boolean;
  fontColor?: string;
  onPress?: () => void;
  isSmall?: boolean;
}

const Ball = ({
  ballSize = 56,
  digit = '0',
  disabled = false,
  color,
  onlyColor = false,
  fontColor,
  fontSize = 24,
  isSmall,
  onPress,
}: BallProps) => {
  const bg = React.useMemo(() => {
    if (onlyColor) {
      if (color === 'g') {
        return BALLS.green;
      }
      if (color === 'v') {
        return BALLS.violet;
      }
      if (color === 'r') {
        return BALLS.red;
      }
    } else {
      if (color === 'g') {
        return disabled ? BALLS.colorGreenDisabled : BALLS.colorGreen;
      }
      if (color === 'r') {
        return disabled ? BALLS.colorRedDisabled : BALLS.colorRed;
      }
      if (color === 'g,v') {
        return disabled ? BALLS.colorGreenVDisabled : BALLS.colorGreenV;
      }
      if (color === 'r,v') {
        return disabled ? BALLS.colorRedVDisabled : BALLS.colorRedV;
      }
      if (color === '*') {
        return BALLS.colorNone;
      }
    }
  }, [disabled, color, onlyColor]);

  const num = React.useMemo(() => {
    if (onlyColor) {
      const text = digit === 'g' ? 'GREEN' : digit === 'r' ? 'RED' : 'VIOLET';
      return isSmall ? text[0] : text;
    } else {
      return digit;
    }
  }, [onlyColor, digit, isSmall]);

  return (
    <NativeTouchableOpacity
      disabled={disabled}
      onPress={() => onPress && onPress()}
      activeOpacity={onPress ? 0.8 : 1}>
      <ImageBackground
        style={[
          {
            width: ballSize,
            height: ballSize,
          },
          theme.flex.center,
        ]}
        source={bg}>
        <Text
          fontFamily="fontInterBold"
          fontSize={fontSize}
          color={fontColor || 'var(--T4)'}>
          {num}
        </Text>
      </ImageBackground>
    </NativeTouchableOpacity>
  );
};

export default Ball;
